import React from "react"
import Layout from "../../components/Layout"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { Link } from "gatsby"

export default function SeriesIndexPage({ data }) {
  const series = data.series.edges.map(({ node }) => node.frontmatter)
  const images = data.media.edges.map(({ node }) => node.frontmatter)
  const seriesFilteredMoreThenThree = series.filter(
    ({ slug }) =>
      images
        .map(({ series }) => series)
        .reduce((all, curr) => all.concat(curr))
        .filter(item => item === slug).length > 2
  )

  return (
    <Layout subTitle={"series"}>
      <div className={"h-full tablet:flex tablet:flex-wrap overflow-hidden"}>
        {seriesFilteredMoreThenThree.map(({ slug, thumb }, index) => (
          <figure
            className={
              "relative my-px px-px overflow-hidden tablet:w-1/2 laptop:w-1/3 desktop:w-1/3 hover:text-white transition ease-in-out duration-300"
            }
            key={index}
          >
            <Link to={`${slug}/0`}>
              {thumb ? (
                <Img
                  className={"h-full filter-grayscale hover:filter-none"}
                  fluid={{
                    ...thumb.image.childImageSharp.fluid,
                    aspectRatio: 1,
                  }}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              ) : (
                <img
                  className={
                    "w-full h-full object-cover filter-grayscale hover:filter-none"
                  }
                  src={"https://www.stevensegallery.com/340/340"}
                  alt="placeholder"
                />
              )}
              <figcaption
                className={
                  "text-lg absolute inset-y-0 w-full flex justify-center items-center pointer-events-none"
                }
              >
                {slug}
              </figcaption>
            </Link>
          </figure>
        ))}
      </div>
    </Layout>
  )
}

export const seriesIndexPageQuery = graphql`
  query SeriesIndexPage {
    series: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "series" } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
            thumb {
              image {
                childImageSharp {
                  fluid(maxWidth: 540) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }

    media: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "media" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            series
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
